import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"

export const blogListQuery = graphql`
  query BlogListQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            # ready
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`

// const variants = {
//   initial: { y: "100%", opacity: 0 },
//   enter: { y: 0, opacity: 1, transition },
//   exit: { y: "-100%", opacity: 0, transition },
// }

const Pagination = props => (
  <div className="pagination">
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>{" "}
            Previous
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={`${props.blogSlug}${i === 0 ? "" : i + 1}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Next{" "}
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const blogVariants = {
  enter: { transition: { staggerChildren: 0.1 } },
  exit: { transition: { staggerChildren: 0.1 } },
}

const BlogIndex = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const blogSlug = "/blog/"
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? blogSlug : blogSlug + (currentPage - 1).toString()
  const nextPage = blogSlug + (currentPage + 1).toString()

  let posts = []

  posts =
    data &&
    data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <PostCard key={edge.node.id} data={edge.node} />)

  let props = {
    isFirst,
    prevPage,
    numPages,
    blogSlug,
    currentPage,
    isLast,
    nextPage,
  }

  const title =
    currentPage && numPages
      ? `Blog Post — Page ${currentPage} of ${numPages}`
      : "Blog Post"

  const desc =
    currentPage && numPages
      ? `Kikxle base blog page ${currentPage} of ${numPages}`
      : "Kikxle base blog page"

  return (
    <AnimatePresence exitBeforeEnter>
      <Layout className="blog-page">
        <SEO title={title} description={desc} />
        <motion.div
          key="blog-list"
          className="wrapper"
          initial="initial"
          animate="enter"
          exit="exit"
          variants={blogVariants}
        >
          <h1 className="page-title">
            <Link className="active" to="/blog">
              Blog Post
            </Link>
            <Link to="/other-works"> Mini Works</Link>
          </h1>
          <hr />
          {posts ? (
            <div className="grids col-1 sm-2 md-2">{posts}</div>
          ) : (
            <p className="empty">There are no posts.</p>
          )}
        </motion.div>
        {data && <Pagination {...props} />}
      </Layout>
    </AnimatePresence>
  )
}

export default BlogIndex
