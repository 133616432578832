import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

const transition = { duration: 0.5, ease: "easeInOut" }

const postPreviewVariants = {
  initial: { x: "100px", opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: { x: "-100px", opacity: 0, transition },
}

const PostCard = ({ data }) => {
  const tags = data.frontmatter.tags || []
  return (
    <motion.article className={data.frontmatter.ready ? `post-card` : `post-card not-ready`} variants={postPreviewVariants}>
      <div className="post-card-content">
        {data.frontmatter.featuredImage ? (
          <Link to={data.frontmatter.slug}>
            <Img
              fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={data.frontmatter.title + " - Featured image"}
              className="featured-image"
            />
          </Link>
        ) : (
          ""
        )}
        <div className="post-content">
          {tags.map(tag => (
            <p className="tag">{tag}</p>
          ))}
          <h2 className="title">
            <Link to={data.frontmatter.slug}>{data.frontmatter.title}</Link>
          </h2>
          <p className="meta">
            <time>{data.frontmatter.date}</time>
          </p>
          {data.frontmatter.description ? (
            <p className="excerpt">
              {data.frontmatter.description.slice(0, 30)}
            </p>
          ) : null}
        </div>
      </div>
      <div className="hover"></div>
    </motion.article>
  )
}

export default PostCard
